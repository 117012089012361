@import './_variables.css';

.tile {
  position: relative;
  width: 74px;
  height: 74px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.tile.mod-scroll-menu {
  width: 75px;
  height: 75px;
}

.tile.mod-scroll-menu > .tile-content:not(.mod-transparent) {
  border-radius: 20px;
}

.tile.mod-small {
  width: 50px;
  height: 50px;
  padding: 3px;
}

.tile.mod-selected {
  padding: 0;
}

.tile.mod-scroll-menu.mod-selected > div.tile-content:not(.mod-transparent) {
  border: 3px solid red;
}

.tile.mod-wide {
  width: 111px;
}

.tile.mod-wide.mod-small {
  width: 100px;
}

.tile.mod-wideWithText {
  width: 111px;
}

.tile.mod-wideWithPadding {
  width: 111px;
}

.tile.mod-wideWithPadding .tile-content {
  padding: 10px;
  background-origin: content-box;
}

.tile-glossy,
.tile-secondaryImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tile-select {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 23px;
  height: 23px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-red);
}

.tile.mod-scroll-menu.mod-selected
  > .tile-content:not(.mod-transparent)
  + .tile-select {
  top: 6px;
  right: 6px;
  border-radius: 0 10px 0 10px;
}

.tile-dashed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: var(--color-gray);
}

.tile-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.tile.mod-disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.tile-content.mod-transparent {
  color: var(--color-midGray);
}

.tile-content.mod-midGray {
  background-color: var(--color-midGray);
}

.tile-content.mod-lighterGray {
  background-color: var(--color-lightGray);
  color: var(--color-midGray);
}

.tile-text {
  padding: 0 2px;
}

.tile-stripe {
  width: 16px;
  height: 100%;
}

.tile.mod-selected .tile-stripe {
  width: 19px;
}

@media screen and (min-width: 768px) {
  .tile {
    padding: 10px;
    transition: 30ms padding linear;
  }

  .tile:not(.mod-disabled):not(.mod-selected):hover {
    padding: 5px;
  }

  .tile.mod-small:not(.mod-disabled):not(.mod-selected):hover {
    padding: 1px;
  }

  .tile.mod-wide,
  .tile.mod-wideWithText,
  .tile.mod-wideWithPadding {
    width: 184px;
    height: 116px;
  }

  .tile.mod-wide.mod-small {
    width: 100px;
    height: 64px;
  }

  .tile-select {
    width: 26px;
    height: 26px;
  }

  .tile-content {
    font-size: 13px;
  }

  .tile.mod-selected .tile-stripe {
    width: 21px;
  }
}
