@import './_variables.css';
body {
  min-height: 400px;
}

.Layout {
  position: absolute;
  left: 0;
  top: 0;
  z-index: var(--z-base);
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: var(--background-solid);
}
.Layout.isCountDownVisible {
  height: calc(100% - 38px);
}

.Layout.mod-finalize {
  overflow-x: auto;
  height: auto;
  min-height: 100vh;
}

.Layout-logo {
  position: absolute;
  z-index: var(--z-logo);
  top: 40px;
  left: 20px;
  width: 60px;
  height: 60px;
}

.Layout-select {
  position: relative;
  height: 100%;
  z-index: var(--z-select);
  background: var(--background-mobile);
}

.Layout-controls {
  position: absolute;
  top: 57px;
  right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  margin-right: 10px;

  .icon {
    height: 24px;
  }
}

@media (max-width: 375px) {
  .Layout-logo {
    top: 26px;
  }
  .Layout-logo.isCountDownVisible {
    top: 60px;
  }
  .Layout-logo .logo-image {
    width: 48px;
  }
  .Layout-controls {
    top: 36px;
  }
}

.Layout-back {
  display: flex;
  align-items: center;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.Layout-search {
  display: flex;
  width: 24px;
  margin-left: 20px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.Layout-navigation {
  position: absolute;
  z-index: var(--z-navigation);
  width: 100%;
  height: var(--height-navigation--sm);
  left: 0;
  bottom: 0;
}

.Layout-navigation.is-open {
  height: 100%;
}

.Layout-renderer {
  position: absolute;
  z-index: var(--z-renderer);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.Layout-rendererBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-mobile);
}

.Layout-purchase {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
}

.Layout-optionsBar {
  position: absolute;
  z-index: var(--z-options-bar);
  left: 0;
  bottom: var(--height-navigation--sm);
  width: 100vw;
}

.Layout.mod-select .Layout-navigation,
.Layout.mod-select .Layout-purchase,
.Layout.mod-select .Layout-optionsBar {
  display: none;
}

.Layout.mod-select .Layout-select {
  display: block;
}
@media (min-width: 769px) {
  .Layout-logo {
    top: 56px;
    left: 30px;
    width: 100px;
    height: 100px;
  }

  .Layout-select {
    background: var(--background-desktop);
  }

  .Layout-rendererBackground {
    background: var(--background-desktop);
    left: 220px;
  }

  .Layout-navigation {
    top: 100px;
    bottom: auto;
    width: auto;
    height: calc(100vh - 100px);
  }
  .Layout.isCountDownVisible .Layout-navigation {
    height: calc(100% - 140px);
  }

  .Layout-optionsBar {
    bottom: 0;
  }

  .Layout-purchase {
    left: auto;
    top: -40px;
    right: 30px;
    bottom: auto;
    height: auto;
    z-index: 3;
  }
}
