@import './_variables.css';

.button {
  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: 16px;
  height: 44px;
  text-decoration: none;
  background-color: var(--color-red);
  color: var(--color-white);
  text-transform: uppercase;
  border: 0 none;
  border-radius: 0;
  transition: background 0.1s;
}

.button:hover,
.button:focus,
.tileCard:hover .button {
  background-color: hsl(from var(--color-red) h s calc(l * 0.85));
  outline: 0 none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.button.mod-lightGray {
  background-color: var(--color-lightGray);
}

.button.mod-lightGray:hover {
  background-color: hsl(from var(--color-lightGray) h s calc(l * 0.85));
}

.button.mod-gray {
  background-color: var(--color-gray);
}

.button.mod-gray:hover {
  background-color: hsl(from var(--color-gray) h s calc(l * 0.85));
}

.button.mod-white {
  background-color: var(--color-white);
  color: var(--color-darkGray);
}

.button.mod-white svg {
  fill: var(--color-darkGray);
}

.button.mod-white:hover {
  background-color: hsl(from var(--color-white) h s calc(l * 0.95));
}

.button.mod-midGray {
  background-color: var(--color-midGray);
}

.button.mod-midGray:hover,
.tileCard:hover .button.mod-midGray {
  background-color: hsl(from var(--color-midGray) h s calc(l * 0.85));
}

.button.mod-full {
  display: block;
}

.button.mod-xlarge {
  font-size: 18px;
  height: 60px;
}

@media (min-width: 768px) {
  .button.mod-small--mediumScreen {
    font-size: 18px;
    height: 39px;
  }
}

.button.mod-onlyIcon {
  padding: 0;
}

.button.mod-hasIcon .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.mod-hasIcon .icon {
  margin-right: 9px;
}

.button.mod-chrome {
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-appearance: none;
}

.button.mod-control .button-container {
  position: relative;
  display: block;
  line-height: 0;
}

.button.mod-control .button-container:after {
  content: ' ';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.button.mod-disabled {
  cursor: not-allowed;
}

.button.mod-disabled.mod-gray:hover {
  background-color: var(--color-gray);
}
