@import './_variables.css';

.icon.mod-cart,
.icon.mod-cart-filled {
  width: 14px;
  height: 15px;
  fill: var(--color-white);
}

.icon.mod-share {
  width: 16px;
  height: 20px;
  fill: var(--color-white);
}

.icon.mod-checkmark {
  width: 16px;
  height: 14px;
  fill: var(--color-white);
}

.icon.mod-info {
  width: 16px;
  height: 16px;
}

.icon.mod-arrow-back,
.icon.mod-arrow-forward {
  width: 18px;
  height: 33px;
  fill: var(--color-darkGray);
}

.icon.mod-inverted {
  fill: var(--color-lighterGray);
}

.icon.mod-arrow-up,
.icon.mod-arrow-down {
  width: 33px;
  height: 18px;
  fill: var(--color-darkGray);
}

.button:not(.mod-disabled) .icon.mod-arrow-up,
.button:not(.mod-disabled) .icon.mod-arrow-down,
.button:not(.mod-disabled) .icon.mod-arrow-back,
.button:not(.mod-disabled) .icon.mod-arrow-forward {
  opacity: 0.6;
}

.button:hover:not(.mod-disabled) .icon.mod-arrow-up,
.button:hover:not(.mod-disabled) .icon.mod-arrow-down,
.button:hover:not(.mod-disabled) .icon.mod-arrow-back,
.button:hover:not(.mod-disabled) .icon.mod-arrow-forward {
  opacity: 1;
  cursor: pointer;
}

.button.mod-disabled .icon.mod-arrow-up,
.button.mod-disabled .icon.mod-arrow-down,
.button.mod-disabled .icon.mod-arrow-back,
.button.mod-disabled .icon.mod-arrow-forward {
  opacity: 0.2;
}

@media screen and (min-width: 768px) {
  .icon.mod-checkmark {
    width: 18px;
  }
}
