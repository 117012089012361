:root {
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-lighterGray: rgb(210, 210, 210);
  --color-lightGray: rgb(180, 180, 180);
  --color-gray: rgb(133, 133, 133);
  --color-midGray: rgb(90, 90, 90);
  --color-darkGray: rgb(39, 39, 39);
  --color-darkerGray: rgb(14, 14, 14);
  --color-red: rgb(247, 28, 37);
  --color-darkRed: rgb(192, 7, 14);
  --color-yellow: rgb(244, 209, 0);
  --color-purple: rgb(41, 24, 61);
  --color-blue: rgb(4, 102, 202);

  --text-color: var(--color-darkGray);

  --z-base: 1;
  --z-renderer: 2;
  --z-scroll-menu: 3;
  --z-purchase: 4;
  --z-options-bar: 5;
  --z-select: 6;
  --z-logo: 7;
  --z-navigation: 8;
  --z-waiting: 9;
  --z-menu-bar: 10;

  --height-navigation--sm: 60px;

  --background-solid: white;
  --background-mobile: white;
  --background-desktop: white;
  --background-finalize: white;
}
