@import './_variables.css';

.menu-bar {
  position: absolute;
  top: 0;
  z-index: var(--z-menu-bar);
  padding-left: 5px;
  background-color: var(--color-darkGray);
  color: var(--color-white);
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 40px;
  max-height: 40px;

  .icon.mod-bars {
    width: 18px;
    height: 18px;
  }

  .icon-container {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-lighterGray);
  }

  .return-to-site-url-container {
    display: flex;
    align-items: center;
  }

  .return-to-site-url {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
  }

  .return-icon {
    margin-right: 6px;
    margin-bottom: 2px;
  }

  .return-icon .icon {
    opacity: 0.8;
    height: 8px;
    width: 8px;
  }

  .return-url {
    text-transform: uppercase;
    font-size: 12px;
    color: var(--color-lighterGray);
    font-weight: bold;
  }

  .product-info {
    margin-top: 8px;
  }

  .button-container {
    display: flex;
    align-items: center;
  }

  button {
    border: 0;
    text-transform: uppercase;
  }

  .menu-button {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--color-lighterGray);
    font-weight: bold;
    background: none;
    border: none;
    min-width: 100px;
  }

  .add-to-cart-button {
    cursor: pointer;
    color: white;
    background: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 130px;
    font-family: revert;
    font-size: 13px;
    font-weight: 500;
    height: 30px;
    border-radius: 10px;
    margin: 5px;
  }
}

.menu {
  z-index: var(--z-menu-bar);
  position: absolute;
  inset: 0;

  .overlay {
    cursor: pointer;
    width: 100vw;
    height: 110vh;
    background: rgba(0, 0, 0, 0.5);
  }

  .menu-container {
    max-height: 100vh;
    height: 100%;
    width: 400px;
    display: flex;
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 0;
    background: var(--color-lighterGray);
    overflow: auto;
  }

  .purchase-container {
    padding-right: 100px;
    margin-top: -50px;
  }

  .menu-close {
    display: inline-block;
    position: sticky;
    align-self: flex-end;
    top: 12px;
    right: 12px;
    padding: 8px;
    cursor: pointer;
    line-height: 0;
    border-radius: 8px;
    transition: background-color 0.2s;
  }

  .menu-close:hover {
    background-color: var(--color-lightGray);
  }

  .menu-items {
    font-size: 13pt;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .section {
      margin-bottom: 10px;
      margin-left: 40px;

      &.has-sub-items > .title {
        display: flex;
        gap: 3px;
        cursor: pointer;
        height: 30px;
        align-items: center;
      }
    }

    .title {
      margin-bottom: 3px;
    }

    .title.selected {
      font-weight: bold;
    }

    .sub-items {
      margin-bottom: 20px;
      font-weight: 300;
      .icon {
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }

    .sub-item {
      cursor: pointer;
    }

    a {
      color: black;
      text-decoration: none;
    }
  }

  .product-info {
    padding: 20px 0 20px 40px;

    strong {
      font-size: 16pt;
      font-weight: bold;
    }
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  }

  svg.icon {
    width: 15px;
    height: 15px;
  }

  .product-title {
    font-size: 16pt;
  }

  .purchase-container {
    padding: 10px;
    text-align: left;
  }

  .product-price {
    text-align: left;
  }

  .button {
    border-radius: 10px;
    width: 180px;
    margin-right: 20px;
  }
}
