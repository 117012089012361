@media print {
  html body * {
    color: black;
    background: none !important;
  }

  html body .product-controls > * {
    display: none;
  }

  html body .product-controls {
    margin-bottom: 20px;
  }

  .Layout {
    width: 100%;
  }
  .finalize-sidebar,
  .Layout.mod-finalize {
    overflow: visible;
  }

  .Layout + div,
  .return-to-site {
    display: none;
  }

  .Layout-logo {
    top: 0;
    left: 0;
  }

  .finalize {
    width: 100%;
  }

  .finalize-preview {
    display: block;
    width: 50%;
    float: left;
  }

  .finalize-sidebar {
    width: 50%;
    float: right;
  }

  .product-title {
    font-size: 30px;
    line-height: 41px;
    text-align: right;
  }

  .product-configurationId {
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-midGray);
    text-align: right;
    margin-bottom: 70px;
  }

  .finalize-product {
    margin-bottom: 18px;
  }

  .product-price,
  .finalize-share {
    display: none;
  }

  .product-price.mod-print {
    display: block;
  }

  .finalize-printPrice {
    display: flex;
    justify-content: space-between;
  }

  .finalize-printPriceLabel,
  .finalize-printPriceValue {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    margin-top: 50px;
  }

  .summary-section + .summary-section {
    margin-top: 18px;
  }

  .summary-label,
  .summary-value {
    font-size: 16px;
    line-height: 30px;
  }

  .summary-sectionTitle {
    font-size: 16px;
    line-height: 22px;
  }

  .gallery {
    padding-top: 140px;
    width: 75%;
    padding-left: 12.5%;

    &-arrow,
    &-bullets {
      display: none;
    }

    &-image {
      display: static;
      width: 100%;

      & + & {
        margin-top: 50px;
      }
    }
  }
}
