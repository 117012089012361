@import './_variables.css';

.waiting {
  position: fixed;
  z-index: var(--z-waiting);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(from var(--color-midGray) r g b / 80%);
}

.waiting-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    margin: 0 auto;
  }
  .text {
    margin-top: 10px;
    color: var(--color-lighterGray);
  }
}
