.TextAndFontInput {
  display: flex;
  justify-content: center;

  &.hasMargin {
    margin-bottom: 15px;
  }

  .textInput {
    width: 300px;
  }

  .select {
    display: flex;
    margin-left: 10px;
    height: 50px;

    & > div {
      width: 42px;
      height: 42px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #5a5a5a;
      position: relative;
      top: 4px;
      margin: 0 5px;
      cursor: pointer;

      &.isActive {
        position: static;
        top: 0;
        width: 50px;
        height: 50px;
      }

      &.isDisabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
