@import './_variables.css';

.spinner {
  width: 70px;
  height: 70px;
  border: 10px solid rgb(from var(--color-darkGray) r g b / 50%);
  border-top-color: var(--color-red);
  border-radius: 50%;
  animation: rotate 300ms linear infinite;
}

.spinner.mod-small {
  width: 24px;
  height: 24px;
  border-width: 4px;
}

.spinner.mod-center {
  margin: 0 auto;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
