@import './_variables.css';

.return-to-site {
  position: absolute;
  padding-left: 20px;
  padding-top: 6px;
  z-index: 6;
}
.return-to-site-url {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: var(--color-gray);
}
.return-icon {
  margin-right: 4px;
  margin-bottom: 2px;
}
.return-icon .icon {
  opacity: 0.8;
  height: 8px;
  width: 8px;
}
.return-url {
  text-transform: uppercase;
  font-size: 12px;
}

@media (min-width: 769px) {
  .return-to-site {
    padding-left: 30px;
    padding-top: 10px;
  }
}
@media (max-width: 375px) {
  .return-to-site {
    padding-top: 2px;
  }
}
