@import './_variables.css';

@media screen {
  .gallery {
    position: relative;
    width: 100vw;
    height: 50vh;
    margin-bottom: 25px;

    &-image {
      display: none;
      user-select: none;

      &.is-active {
        display: block;
        width: auto;
        height: 75%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      transition: opacity 0.1s;
      padding: 20px;
      box-sizing: content-box;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &.mod-prev {
        left: 0;
      }

      &.mod-next {
        right: 0;
      }
    }

    &-bullets {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &-bullet {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      opacity: 0.2;
      transition: opacity 0.1s;
      cursor: default;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.01);
      user-select: none;

      &:after {
        content: '';
        color: var(--color-black);
        background: var(--color-black);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }

      &.is-active {
        opacity: 1;
      }
    }

    @media (min-width: 769px) {
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      &-arrow,
      &-bullets {
        display: none;
      }

      &-image:not(.is-default) {
        display: block;
        width: 30vh;
        max-width: 40%;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(20%, -110%);

        &.mod-back {
          transform: translate(20%, 0%);
        }
      }

      &-image.is-default {
        display: block;
        width: 50vh;
        height: auto;
        max-width: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-90%, -50%);
      }
    }
  }

  .gallery.has-single-preview .gallery-image {
    width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
  }
}
