@import './_variables.css';

.UnrealRenderForm {
  margin-top: 25px;
}

.UnrealRenderForm input[type='email'],
.UnrealRenderForm select {
  width: 270px;
  outline: 0;
}

.UnrealRenderForm a {
  position: relative;
  bottom: 2px;
  left: 4px;
  text-decoration: none;
  font-family: monospace;
  font-weight: bold;
}

.UnrealRenderForm label {
  font-size: 14px;
  color: var(--color-midGray);
}

.UnrealRenderForm .button-wrapper {
  margin-top: 10px;
}
