@import './_variables.css';

.share {
  display: flex;
  justify-content: center;
}

.share-icon {
  width: 32px;
  height: 32px;
  margin: 9px;
  overflow: hidden;
}

.share-icon svg {
  position: relative;
  top: -3px;
  width: 20px;
  height: 20px;
  fill: var(--color-white);
}

@media (min-width: 768px) {
  .share-icon {
    width: 44px;
    height: 44px;
  }

  .share-icon svg {
    top: 3px;
    width: 24px;
    height: 24px;
  }
}
