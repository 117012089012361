@import './_variables.css';

:root {
  --animation-duration--navigation: 0.3s;
  --z-controls--navigation: 2;
  --z-pageTitle--navigation: 1;
}

.Navigation-item {
  & svg.icon {
    width: 14px;
    height: 14px;
    fill: var(--color-midGray);
    margin-left: 10px;
  }
}

.Navigation-new {
  color: var(--color-red);
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  position: relative;
  left: 5px;
  top: -7px;
}

@media (max-width: 768px) {
  .Navigation {
    height: var(--height-navigation--sm);
    background: var(--background-mobile);
    background-attachment: fixed;
    transition: height var(--animation-duration--navigation);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    overflow: hidden;
    user-select: none;
  }

  .Navigation.is-open {
    height: 100%;
  }

  .Navigation-scrollControl {
    display: none;
  }

  .Navigation-itemsList {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100% - var(--height-navigation--sm));
  }

  .Navigation-section:first-child {
    padding-top: 30px;
  }

  .Navigation-section:last-child {
    margin-bottom: 30px;
  }

  .Navigation-productTitle {
    font-weight: 600;
    font-size: 30px;
    margin-left: 30px;
  }

  .Navigation-productPrice {
    font-weight: 600;
    font-size: 20px;
    margin-left: 30px;
  }

  .Navigation-productControls {
    margin-left: 30px;
    margin-bottom: 25px;
    margin-top: 5px;
    width: calc(50% - 5px);
  }

  .Navigation-sectionTitle {
    display: block;
    color: rgb(from var(--color-black) r g b / 20%);
    font-size: 13px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    margin-left: 30px;
  }

  .Navigation-item {
    text-align: left;
    margin-left: 30px;

    &.mod-productSelector {
      margin-bottom: 20px;
    }

    &.mod-summary {
      margin-top: 20px;
    }
  }

  .Navigation-item > .Navigation-item {
    margin-left: 20px;
    overflow: hidden;

    &.is-collapsed {
      height: 0;
    }
  }

  .Navigation-pageTitle,
  .Navigation-link {
    display: inline-block;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 300;
    line-height: 2.5;
    color: var(--color-midGray);
    text-decoration: none;
    cursor: pointer;
  }

  .Navigation-item > .Navigation-item > .Navigation-link {
    font-size: 14px;
  }

  .Navigation-pageTitle {
    color: var(--color-white);
    background-color: var(--color-midGray);
    display: flex;
    width: 100vw;
    height: var(--height-navigation--sm);
    line-height: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: var(--z-pageTitle--navigation);
    bottom: 0;
    left: 0;
    padding: 0 100px;
  }

  .Navigation-pageTitleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Navigation-pageTitleBox svg {
    position: absolute;
    left: 75px;
  }

  .Navigation-pageTitle svg {
    width: 18px;
    height: 18px;
    fill: var(--color-white);
  }

  .Navigation:not(.is-open) .Navigation-pageTitle.mod-menu,
  .Navigation.is-open .Navigation-pageTitle:not(.mod-menu) {
    display: none;
  }

  .Navigation .Navigation-link.is-active {
    font-weight: 700;
  }

  .Navigation-control {
    position: absolute;
    z-index: var(--z-controls--navigation);
    display: block;
    bottom: 0;
    width: var(--height-navigation--sm);
    height: var(--height-navigation--sm);
    line-height: var(--height-navigation--sm);
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    transform: translateX(0%);
    transition: transform var(--animation-duration--navigation);
    cursor: pointer;
  }

  .Navigation-control svg.icon {
    width: 15px;
    height: 28px;
    vertical-align: middle;
    fill: var(--color-white);
    opacity: 0.2;
  }

  a.Navigation-control svg.icon {
    opacity: 1;
  }

  .Navigation-control[href=''] {
    color: rgb(from #d8d8d8 r g b / 20%);
  }

  .Navigation-control.mod-left {
    left: 0;
    border-right: 1px solid rgb(from #d8d8d8 r g b / 20%);
  }
  .Navigation.is-open .Navigation-control.mod-left {
    transform: translateX(-100%);
  }

  .Navigation-control.mod-right {
    right: 0;
    border-left: 1px solid rgb(from #d8d8d8 r g b / 20%);
  }

  .Navigation.is-open .Navigation-control.mod-right {
    transform: translateX(100%);
  }
}

@media (min-width: 769px) {
  .Navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    padding-left: 30px;

    &-pageTitle,
    &-control {
      display: none;
    }

    &-scrollControl {
      padding: 24px 24px 24px 16px;
      cursor: pointer;
      transition: opacity 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      user-select: none;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      &.is-hidden {
        visibility: hidden;
      }

      &.is-disabled {
        opacity: 0.2;
        cursor: default;
      }
    }

    &-itemsList {
      flex-grow: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      margin-right: -20px;
      padding-right: 20px;
    }

    &-link {
      color: var(--color-black);
      opacity: 0.6;
      transition: opacity 0.1s;

      &.is-active,
      &:hover {
        opacity: 1;
      }
    }

    &-section-summary {
      display: none;
    }

    &-section {
      &.mod-mobileOnly {
        display: none;
      }
    }

    &-item {
      font-size: 20px;
      font-weight: 300;
      line-height: 50px;
      color: var(--color-midGray);

      & > & {
        font-size: 16px;
        line-height: 40px;
        margin-left: 20px;
        height: 40px;
        overflow: hidden;
        transition: height 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &.is-collapsed {
          height: 0;
        }
      }
    }

    &-link {
      cursor: pointer;

      &.is-active {
        font-weight: 700;
      }
    }
  }
}
