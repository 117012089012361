@import './_variables.css';

.scroll-menu {
  position: absolute;
  top: 0;
  right: 0;
  max-width: min(480px, 40%);
  padding-inline: 8px;
  padding-top: 48px;
  z-index: var(--z-scroll-menu);
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: rgba(255, 255, 255, 0.75);

  div [data-component='Tile'] {
    padding: 3px;
  }

  .tile-content {
    background-size: contain;
    box-shadow: 1px 1px 5px #00000075;
  }

  h2 {
    padding-top: 100px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
  }

  /* to scroll h2 to the middle when menu item is clicked */
  .scrollAnchor {
    display: block;
    height: 20vh;
    margin-top: -20vh;
    visibility: hidden;
  }

  .sub-title {
    .name {
      font-weight: bold;
    }
    margin-top: 5px;
    text-align: center;
  }

  .select-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .category-title {
      text-transform: uppercase;
    }

    .category-container {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
      font-size: 12px;
      font-weight: 600;
    }

    .category-items {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .spacer {
    height: 150px;
  }

  .scroll-menu-section {
    max-width: 550px;
    margin-right: 30px;
  }

  .purchase {
    margin-right: 50px;
    .product-product {
      align-items: center;
    }
    .product-controls {
      text-align: center;
    }
  }

  .arrow {
    position: sticky;
    height: 0px;
    overflow: visible;
    cursor: pointer;
  }

  .arrow-up {
    top: 0;
  }

  .arrow-down {
    bottom: 0;

    .arrow-icon {
      top: -40px;
    }
  }

  .arrow-icon {
    display: inline-flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }

  .arrow-icon > svg {
    width: 25px;
  }
}
