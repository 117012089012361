@import './_variables.css';

.optionsBar.mod-dev-outline {
  outline: 1px solid lightgreen;

  & .optionsBar-title {
    outline: 1px solid blue;
  }

  & .optionsBar-scrollContainer {
    outline: 1px solid red;
  }

  & .optionsBar-control {
    outline: 1px solid yellow;
  }

  & .optionsBar-scrollBodyContainer {
    outline: 1px solid magenta;
  }

  & .optionsBar-scrollItem {
    outline: 1px solid springgreen;
  }

  & .tileCard-content > * {
    outline: 1px solid orange;
  }
}

.optionsBar {
  position: relative;
  width: 100vw;
  user-select: none;
  align-items: center;
}

.optionsBar.mod-large {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-height: 568px) {
  .optionsBar.mod-large {
    justify-content: space-between;
  }
}

.optionsBar-title {
  display: block;
  text-align: center;
  color: var(--color-darkGray);
  font-size: 18px;
}

.optionsBar.mod-large .optionsBar-title {
  font-size: 30px;
  font-weight: 300;
  color: var(--color-midGray);
  max-width: calc(100vw - 20px);

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 375px) {
    font-size: 20px;
  }
}

.optionsBar-description {
  font-size: 13px;
  text-align: center;
  line-height: 14px;
}

.optionsBar-input {
  width: 300px;
  margin: 0 auto;
  padding-bottom: 15px;
}

.optionsBar-header {
  position: relative;
  padding-top: 20px;
  flex-grow: 0;

  .optionsBar.mod-large & {
    padding-top: 0;

    &:not(.mod-grid) {
      margin-bottom: 20px;
    }
  }
}

.optionsBar-grid {
  width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
}

.optionsBar-control {
  display: none;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
}

.optionsBar.isWizard .optionsBar-control .mod-control.mod-disabled {
  visibility: hidden;
}

.optionsBar-controlButton {
  width: 24px;
  height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.optionsBar-control.mod-left {
  left: -58px;
}

.optionsBar-control.mod-right {
  right: -58px;
}

.optionsBar.mod-large .optionsBar-control {
  display: block;
  position: fixed;
}

.optionsBar.mod-large .optionsBar-control.mod-left {
  left: 10px;
}

.optionsBar.mod-large .optionsBar-control.mod-right {
  right: 10px;
}

.optionsBar-scrollBodyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;

  .optionsBar.mod-large & {
    flex-grow: 1;
  }
}

.optionsBar-scrollBody {
  padding: 15px 0 10px;
  display: flex;
  width: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.optionsBar-scrollBody.withAnimation {
  transition: all 0.3s cubic-bezier(0.42, 0, 0.855, 1);
}

.optionsBar.mod-large .optionsBar-scrollBody {
  padding-top: 0;
  padding-bottom: 0;
  overflow-x: hidden;
}

.optionsBar-scrollItem {
  box-sizing: content-box;
}

/* need this as scrollBody's right padding is not respected */
@media (max-width: 374px) {
  .optionsBar:not(.mod-large)
    .optionsBar-scrollBody:not(.mod-items-4)
    .optionsBar-scrollItem:last-child {
    padding-right: 15px;
  }

  .optionsBar:not(.mod-large)
    .optionsBar-scrollBody:not(.mod-items-4)
    .optionsBar-scrollItem:first-child {
    padding-left: 15px;
  }
}

@media (min-width: 375px) and (max-width: 768px) {
  .optionsBar:not(.mod-large)
    .optionsBar-scrollBody:not(.mod-items-5)
    .optionsBar-scrollItem:last-child,
  .optionsBar.mod-wide .optionsBar-scrollItem:last-child {
    padding-right: 15px;
  }

  .optionsBar:not(.mod-large)
    .optionsBar-scrollBody:not(.mod-items-5)
    .optionsBar-scrollItem:first-child,
  .optionsBar.mod-wide .optionsBar-scrollItem:first-child {
    padding-left: 15px;
  }
}

.optionsBar-header {
  &,
  .optionsBar.mod-large & {
    padding-top: 100px;

    @media (max-height: 500px) {
      padding-top: 80px;
    }

    @media (max-width: 375px) {
      padding-top: 75px;
    }
  }
}

@media (min-width: 376px) and (max-height: 500px) {
  .optionsBar-header {
    &,
    .optionsBar.mod-large & {
      padding-top: 0;
    }
  }
}

@media (min-width: 769px) {
  .optionsBar {
    position: absolute;
    bottom: 0;
    right: 0;
    user-select: auto;
  }

  .optionsBar:not(.mod-large) {
    width: calc(100vw - 230px);
  }

  .optionsBar-header {
    padding-top: 0;
    flex-grow: 0;
  }

  .optionsBar-title {
    font-size: 24px;
    padding-bottom: 4px;
    pointer-events: none;
  }

  .optionsBar-description {
    margin: 0 auto;
    height: 18px;
  }

  .optionsBar-title,
  .optionsBar-description {
    -webkit-font-smoothing: antialiased;
    text-shadow:
      -1px -1px 0px var(--background-solid),
      0px -1px 0px var(--background-solid),
      1px -1px 0px var(--background-solid),
      1px 0px 0px var(--background-solid),
      1px 1px 0px var(--background-solid),
      0px 1px 0px var(--background-solid),
      -1px 1px 0px var(--background-solid),
      -1px 0px 0px var(--background-solid);
  }

  .optionsBar-chrome {
    display: flex;
    justify-content: center;
  }

  .optionsBar-scrollContainer {
    min-width: auto;
    position: relative;
    display: block;
    margin-right: 80px;
    margin-left: 80px;
    margin-bottom: 0;
  }

  .optionsBar.mod-large .optionsBar-scrollContainer {
    display: flex;
    align-items: flex-start;
  }

  .optionsBar-control {
    display: block;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 44px;
  }

  .optionsBar.mod-large .optionsBar-scrollItem {
    display: flex;
    align-items: center;
  }

  .optionsBar-control.mod-left {
    left: -53px;
  }

  .optionsBar-control.mod-right {
    right: -53px;
  }

  .optionsBar.mod-large .optionsBar-control.mod-left {
    left: 20px;
  }

  .optionsBar.mod-large .optionsBar-control.mod-right {
    right: 20px;
  }

  .optionsBar-scrollContainer.mod-allFits .optionsBar-control {
    display: none;
  }

  .optionsBar-scrollBody {
    overflow: hidden;
  }

  .optionsBar.mod-large .optionsBar-scrollBodyContainer {
    margin-right: 0;
    height: auto;
  }

  .optionsBar-scrollBodyContainer {
    margin-right: 82px;
  }
}

@media (min-width: 480px) and (max-width: 768px) and (max-height: 768px) {
  .optionsBar.mod-large .optionsBar-title {
    padding-top: 40px;
  }
}
@media (min-width: 480px) and (max-width: 768px) and (max-height: 400px) {
  .optionsBar.mod-large .optionsBar-title {
    padding-top: 10px;
  }
}
