@import './_variables.css';

:root {
  --width-sidebar--finalize: 325px;
  --width-preview--finalize: calc(100vw - var(--width-sidebar--finalize));
}

.finalize {
  position: relative;
  width: 100vw;
  min-height: 100%;
}

.finalize a {
  color: var(--color-darkGray);
}

.finalize-preview {
  display: flex;
  width: 100vw;
  height: 50vh;
  align-items: center;
  justify-content: center;
  color: var(--color-lightGray);
  background: var(--background-desktop);
}

.finalize-customizeIcon {
  width: 19px;
  height: auto;
  margin-right: 9px;
}

.finalize-sidebar,
.finalize-purchase {
  margin-bottom: 20px;
}

.finalize-sidebar {
  width: 100vw;
  padding: 0 20px;
  max-width: 535px;
  margin-left: auto;
  margin-right: auto;
}

.finalize-printPrice {
  display: none;
}

.finalize-share {
  padding-bottom: 25px;
}

@media (min-width: 769px) {
  .finalize-preview {
    width: var(--width-preview--finalize);
    height: calc(100vh - 104px);
  }

  .finalize-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--width-sidebar--finalize);
    height: 100vh;
    padding: 0 30px 0 0;
    overflow-y: auto;
  }

  .finalize-purchase {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  .finalize-share {
    width: var(--width-preview--finalize);
  }
}
