@import '../../../_rawlings/client/components/_variables.css';

.viewSwitcher {
  &-close {
    position: fixed;
    z-index: 2;
    top: 10px;
    right: 10px;
    width: 22px;
    opacity: 0.5;
    transition: opacity 0.1s;
    height: 22px;
    padding: 20px;
    box-sizing: content-box;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-arrow {
    position: fixed;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
    transition: opacity 0.1s;
    padding: 30px;
    box-sizing: content-box;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    &.mod-prev {
      left: 0;
    }

    &.mod-next {
      right: 0;
    }
  }

  &-bullets {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-bullet {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    opacity: 0.2;
    transition: opacity 0.1s;
    cursor: default;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.01);
    user-select: none;

    &:after {
      content: '';
      color: var(--color-black);
      background: var(--color-black);
      border-radius: 50%;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }

    &.is-active {
      opacity: 1;
    }
  }
}
