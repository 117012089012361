@import './_variables.css';

.textInput {
  width: 100%;
  text-align: center;
}

.textInput-input {
  margin: 0;
  padding: 8px 0;
  border: 0;
  background: none;
  border-bottom: 2px solid var(--color-midGray);
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: var(--color-darkGray);
  width: 100%;
  outline: none;
}

.textInput-label {
  font-size: 14px;
  line-height: 19px;
  color: var(--color-midGray);
  text-align: center;
  margin-top: 5px;
}

.textInput.hasError .textInput-input {
  color: red;
  border-color: red;
}
.textInput.hasError .textInput-label {
  color: red;
}
