@import './_variables.css';
@import './Print.css';

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
}

#root {
  height: 100%;
}
