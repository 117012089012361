@import './_variables.css';

.wizard-title {
  display: block;
  text-align: center;
  color: var(--color-darkGray);
  font-size: 18px;
}

.optionsBar.mod-large .wizard-title {
  font-size: 30px;
  font-weight: 300;
  color: var(--color-midGray);
  max-width: calc(100vw - 20px);

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 375px) {
    font-size: 20px;
  }
}

.wizard-grid-container {
  height: 100%;
  padding: 20px;
}

.wizard-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wizard-options-container {
  max-width: 1620px;
  height: 100%;
  display: flex;
  overflow: scroll;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 10px;
}

.wizard-options-container::-webkit-scrollbar {
  display: none;
}

.wizard-scrollItem {
  box-sizing: content-box;
  padding: 0 10px;
}

.wizard-scrollItem:last-of-type {
  padding-bottom: 40px;
}

.wizard-actions {
  position: absolute;
  bottom: 0;
  height: 118px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;

  @media (max-width: 769px) {
    height: 88px;
  }

  @media (max-height: 768px) {
    height: 88px;
  }

  @media (min-width: 769px) {
    position: static;
    width: auto;
  }

  .optionsBar.mod-large & {
    position: static;
    flex-grow: 0;
  }
}

.wizard-noPreference {
  font-size: 18px;
  font-weight: lighter;
  color: var(--color-midGray);
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  height: 24px;

  &:hover {
    opacity: 0.7;
  }
}

.wizard-search {
  margin: 0 auto;
  width: 100%;
  max-width: calc(100vw - 120px);

  @media (min-width: 769px) {
    width: 400px;
  }
}

.scroll-down-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  cursor: pointer;
  padding-bottom: 10px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
