@import './_variables.css';

.fileInput {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.fileInput-input {
  position: relative;
  background-color: var(--color-lightGray);
  width: 100%;
  text-align: left;
  display: flex;
}

.fileInput-placeholder,
.fileInput-value {
  flex-grow: 1;
  line-height: 32px;
  text-indent: 14px;
  font-size: 14px;
  color: var(--color-darkGray);
}

.fileInput-button {
  width: 84px;
  height: 32px;
}

.fileInput-ghost {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.fileInput-label {
  font-size: 14px;
  line-height: 19px;
  color: var(--color-midGray);
  text-align: center;
  margin-top: 5px;
}

@media (min-width: 768px) {
  .fileInput-placeholder,
  .fileInput-value {
    line-height: 44px;
  }

  .fileInput-button {
    height: 44px;
  }
}
