@import './_variables.css';

.count-down {
  width: 100%;
  background: var(--color-red);
  text-align: center;
  z-index: 3;
  position: relative;
  color: var(--color-white);
  font-weight: 600;
  padding: 8px 16px;
  font-size: 16px;
  display: none;
  height: 38px;
}
.count-down.isCountDownVisible {
  display: block;
}
.count-down span {
  font-weight: 400;
}
@media (max-width: 769px) {
  .count-down {
    font-size: 13px;
    padding: 8px;
  }
}
@media (max-width: 280px) {
  .count-down {
    font-size: 10px;
    padding: 4px;
  }
}
