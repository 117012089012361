@import './_variables.css';

.product-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  color: var(--color-darkGray);
  margin-bottom: 5px;
}

.product-price {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-align: right;
  color: var(--color-darkGray);
  margin-top: 8px;
  margin-bottom: 15px;
}

.product-configurationId {
  text-align: center;
}

.product-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;

  .purchase-product.mod-infoCard & {
    display: block;
    width: 200px;
    text-align: right;
  }
}

.product-controls .button {
  width: calc(50% - 5px);
}

@media (min-width: 769px) {
  .product-title {
    font-size: 30px;
    line-height: 41px;
    text-align: left;
    margin-top: 70px;
  }

  .product-configurationId {
    display: block;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-midGray);
    margin-bottom: 20px;
  }
}

@media screen {
  .product-price.mod-print {
    display: none;
  }
}
