@import './_variables.css';

.preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 40px 10px 200px 10px;
}
.preview.is-focus {
  margin: 0;
}
.preview.is-focus.has-gaps {
  margin: 20px 10px;
}
.preview.is-covering-background {
  margin: 0;
}
.preview.is-focus.has-more-than-one {
  /* Space for .viewSwitcher-bullets */
  margin-bottom: 60px;
}
.viewSwitcher-arrow {
  display: none;
}

@media (min-width: 769px) {
  .preview {
    margin: 20px 100px 180px 220px;
  }
  .preview.is-focus.has-more-than-one {
    /* Space for .viewSwitcher-arrow */
    margin-left: 100px;
    margin-right: 100px;
  }
  .viewSwitcher-arrow {
    display: block;
  }
}

#render-composite {
  height: 100%;
  width: 100%;
}

.preview .canvas-container {
  width: 100%;
  height: 100%;
}

.preview canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  outline: 0;
}

.preview .loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.preview-focus {
  position: absolute;
  z-index: var(--z-waiting);
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  opacity: 0.6;
  transition: opacity 0.1s;

  @media (min-width: 769px) {
    top: auto;
    bottom: 170px;
    right: 30px;

    &.mod-is-scroll-menu-layout {
      left: 40px;
      top: 50%;
    }
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  & svg {
    width: 100%;
    height: 100%;
    fill: var(--color-black);
  }

  &.mod-is-scroll-menu-layout {
    bottom: 64px;
    right: min(540px, 45%);
  }
}

.renderer-error {
  text-align: center;
}
.renderer-error h1 {
  font-size: 16px;
  text-transform: uppercase;
  color: var(--color-red);
  line-height: 0;
}
.renderer-error p {
  color: var(--color-midGray);
}

.renderer-loading {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.renderer-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.renderer-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.renderer-inner.mod-is-scroll-menu-layout {
  top: 100px;
  right: 280px;

  @media (max-width: 1150px) {
    canvas {
      margin-left: 130px;
      max-width: 90%;
    }
  }
}

.is-focus .renderer-inner.mod-is-scroll-menu-layout {
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .renderer {
    height: 100%;
    width: 100%;
  }
}

.view-icons.mod-is-scroll-menu-layout {
  left: 15px;
}

.view-icons {
  position: absolute;
  right: 15px;
  width: 64px;
  z-index: 8;
  transition: opacity 0.1s;
  cursor: pointer;

  @media (max-width: 375px) {
    top: 10px;
    right: 10px;

    .view-icon.magnifier {
      height: 32px;

      .label {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 376px) and (max-width: 768px) {
    top: 20px;
  }
  @media (max-width: 768px) {
    .view-icon {
      display: none;
    }
    .view-icon.magnifier {
      display: block;
    }
  }

  @media (min-width: 769px) and (max-height: 680px) {
    bottom: 90px;
  }
  @media (min-width: 769px) and (max-height: 515px) {
    .view-icon {
      display: none;
    }
    .view-icon.magnifier {
      display: block;
    }
  }
  @media (min-width: 769px) and (min-height: 515px) and (max-height: 600px) {
    .view-icon.magnifier {
      display: none;
    }
  }
  @media (min-width: 769px) and (min-height: 681px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .view-icon {
    height: 40px;
    margin-bottom: 40px;
    opacity: 0.6;

    &.magnifier {
      height: 36px;
    }

    &:hover {
      opacity: 1;
    }

    &.is-active {
      opacity: 1;

      .label {
        font-weight: bold;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .label {
      text-align: center;
      font-size: 14px;
    }
  }
}
