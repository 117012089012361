@import './_variables.css';

.purchase {
  position: relative;
  width: 100vw;
  height: 100%;
  display: none;
  padding: 0;
}

.purchase-product {
  position: absolute;
  bottom: var(--height-navigation--sm);
  left: 0;
  width: 100%;
  padding: 0 20px;
}

.purchase.is-on {
  display: block;
}

.purchase .product-configurationId,
.purchase .summary-sectionTitle {
  display: none;
}

@media (min-width: 769px) {
  .purchase {
    position: relative;
    width: auto;
    height: auto;
    display: block;
  }

  .purchase-product {
    position: relative;
    bottom: auto;
    padding: 0;

    & .product-product {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .purchase .summary {
    display: flex;
    justify-content: flex-end;
  }

  .purchase .summary-section {
    width: 200px;
  }

  .purchase .summary-label,
  .purchase .summary-value {
    color: var(--color-gray);
  }

  .purchase .product-title {
    text-align: right;
    font-size: 26px;
    font-weight: 600;
  }

  .purchase .product-price {
    margin-top: -10px;
    margin-bottom: 5px;
    font-size: 20px;
  }

  .purchase .product-controls {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    width: auto;
  }

  .purchase .product-controls .button {
    width: auto;
    margin-left: 10px;
    padding: 0 20px;
  }

  .scroll-menu .purchase .product-controls .button,
  .menu-container .purchase .product-controls .button {
    border-radius: 10px;
  }

  .purchase-summaryLink {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--color-red);
    transition: color 0.1s;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;

    & .icon {
      position: relative;
      top: 2px;
      fill: var(--color-red);
    }

    &:hover {
      color: hsl(from var(--color-red) h s calc(l * 0.85));

      & .icon {
        fill: hsl(from var(--color-red) h s calc(l * 0.85));
      }
    }
  }
}
