.logo {
  position: relative;
}

.logo-link {
  cursor: default;
}

.logo-link.mod-clickable {
  cursor: pointer;
}

.logo-image {
  position: absolute;
  width: 64px;
  opacity: 0.5;
}
