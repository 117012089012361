@import './_variables.css';

.summary {
  position: relative;
}

.summary-section + .summary-section {
  margin-top: 10px;
}

.summary-details {
  display: flex;
  justify-content: space-between;
}

.summary-row .summary-row {
  padding-left: 5px;
}

.summary-sectionTitle {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: rgb(from var(--color-gray) r g b / 50%);
  text-transform: uppercase;
}

.summary-label,
.summary-value {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-midGray);
}

@media (min-width: 769px) {
  .summary-section + .summary-section {
    margin-top: 18px;
  }

  .summary-label,
  .summary-value {
    font-size: 16px;
    line-height: 30px;
  }

  .summary-sectionTitle {
    font-size: 16px;
    line-height: 22px;
  }
}
