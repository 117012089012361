@import './_variables.css';
.tileCard {
  display: flex;
  width: 300px;
  height: 100%;
  flex-direction: column;
  cursor: pointer;
}

.tileCard-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  .optionsBar.mod-large & {
    justify-content: flex-start;
  }
}

.tileCard-image {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  margin-top: calc((220px - 200px) / 2);
  margin-bottom: calc((220px - 200px) / 2);
}

.tileCard.isSelected .tileCard-image {
  width: 220px;
  height: 220px;
  margin-top: 0;
  margin-bottom: 0;
}

.tileCard-imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.tileCard-image .tileCard-checkmark {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background: #f71d25;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tileCard-image img {
  width: 100%;
  height: 100%;
}

.tileCard-name {
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
}

.tileCard-text {
  max-width: 280px;
  font-size: 14px;
  text-align: justify;
}
.tileCard-tileInfo {
  max-width: 280px;
  font-size: 14px;
  text-align: justify;
  margin-top: 16px;
}

.tileCard-textImportant {
  max-width: 280px;
  font-size: 14px;
  text-align: justify;
  padding-top: 20px;
  font-weight: bold;
  font-size: italic;
}

.tileCard-noMargin {
  margin: 0px;
}

@media (max-width: 375px) {
  .tileCard-name {
    font-size: 20px;
  }

  .tileCard-text,
  .tileCard-tileInfo {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .tileCard-image {
    display: flex;
    justify-content: center;
    width: 256px;
    height: 256px;
    margin-top: calc((280px - 256px) / 2);
    margin-bottom: calc((280px - 256px) / 2);
    flex-grow: 0;
  }
  .tileCard.isSelected .tileCard-image {
    width: 280px;
    height: 280px;
  }
  .tileCard-name {
    margin-top: 25px;
    font-size: 26px;
  }
}

@media (max-height: 500px) {
  .tileCard {
    width: 100%;
  }

  .tileCard-content {
    flex-direction: row;
  }

  .tileCard-texts {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .tileCard-name {
    text-align: left;
    margin-top: 0;
  }
}

@media (max-height: 640px) {
  .tileCard-image {
    width: 120px;
    height: 120px;
    margin: calc((140px - 120px) / 2);
    flex-shrink: 0;
  }

  .tileCard.isSelected .tileCard-image {
    margin: 0;
  }

  .tileCard.isSelected .tileCard-image {
    width: 140px;
    height: 140px;
  }
}

.tileCard {
  &-texts {
    opacity: 0.6;
    transition: opacity 0.1s;
  }

  &:hover &-texts {
    opacity: 1;
  }
}
